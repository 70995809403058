import type { LinkProps } from "@remix-run/react";
import { Link as RemixLink } from "@remix-run/react";
import type { ReactElement, ReactNode } from "react";
import "./Button.css";
import type { ButtonWidth } from "./Button.js";
import { Button } from "./Button.js";

interface ButtonLinkProps {
  to: string;
  width?: ButtonWidth;
  className?: string;
  children: ReactNode;
}

export function InternalButtonLink(
  props: ButtonLinkProps & LinkProps
): ReactElement {
  const { to, width, className, children } = props;

  return (
    <RemixLink className="block" to={to}>
      <Button width={width} className={className}>
        {children}
      </Button>
    </RemixLink>
  );
}

export function ExternalButtonLink(
  props: ButtonLinkProps & LinkProps
): ReactElement {
  const { to, width, className, children } = props;

  return (
    <a className="block" href={to}>
      <Button width={width} className={className}>
        {children}
      </Button>
    </a>
  );
}

export default {
  Internal: InternalButtonLink,
  External: ExternalButtonLink,
};
